import React from "react";
import { ThemeProvider } from "styled-components";
import theme from "../../constants/theme";
import Box from "../Box/Box";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import smoothScrollPolyfill from "smoothscroll-polyfill";
import useHasMounted from "../../hooks/usHasMounted";
import { SystemStyleObject } from "@styled-system/css";

if (typeof window !== "undefined" && typeof document !== "undefined") {
  smoothScrollPolyfill.polyfill();
}

interface Props {
  pathname: any;
  children: React.ReactNode;
  menuInitialColor?: string;
  sx?: SystemStyleObject;
}

const Layout = ({ children, menuInitialColor, pathname, sx }: Props) => {
  const isMounted = useHasMounted();

  if (!isMounted) return null;
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          overflow: "hidden",
          minHeight: "calc(100vh - var(--vh-offset,0px))",
          display: "grid",
          position: "relative",
          zIndex: 0,
          ...sx,
        }}
      >
        <Header {...{ menuInitialColor }} />
        <Box
          sx={{
            width: "100%",
            minWidth: "100%",
          }}
        >
          {children}
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default React.memo(Layout);
