import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { get } from "../../constants/theme";
import Fade from "../Animations/Fade";
import Box from "../Box/Box";
import MotionBox from "../Box/MotionBox";
import Link from "./LinkWrapper";
import { GatsbyImage } from "gatsby-plugin-image";
import { NavigationQuery } from "../../types/components/navigationMenu";
import useWindowSize from "../../hooks/useWindowSize";
import { SmallTextWrapper } from "../Styled/Text";

const NavigationModal = () => {
  const [navHeight, setNavHeight] = React.useState<number>(0);
  const [activePicture, setActivePicture] = React.useState<string | null>(null);
  const { height } = useWindowSize();
  const { langVersLinks, routes } = useStaticQuery<NavigationQuery>(graphql`
    query NavigationQuery {
      translationsJson {
        components {
          navigation {
            langVersLinks {
              ru
              en
            }
            routes {
              href
              id
              image {
                relativePath
                childImageSharp {
                  gatsbyImageData
                }
              }
              imageAlt
              order
              title
              disabled
            }
          }
        }
      }
    }
  `).translationsJson.components.navigation;

  const navRef = React.useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setNavHeight(node.clientHeight);
    }
  }, []);

  const changeActivePicture = React.useCallback((id: string) => {
    setActivePicture(id);
  }, []);

  const unsetActivePicture = React.useCallback(() => {
    setActivePicture(null);
  }, []);

  React.useEffect(() => {
    // if (isIOS) document.body.style.position = "fixed";
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "auto";
      // if (isIOS) document.body.style.position = "";
    };
  }, []);

  if (typeof document === "undefined") return null;

  return (
    <Fade>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          zIndex: 100,
          backgroundColor: get("palette.background.gold"),
        }}
      >
        <Box
          ref={navRef}
          sx={{
            position: "fixed",
            top: `${height / 2}px`,
            width: "100%",
            height: "auto",
            marginTop: `-${navHeight / 2}px`,
          }}
        >
          <Box
            as="nav"
            sx={{
              position: "relative",
              flex: 1,
              textTransform: "uppercase",
              margin: "0 15%",
              "& > :last-child": {
                marginBottom: 0,
              },
            }}
          >
            {routes.map((r) => (
              <Box
                key={r.id}
                sx={{
                  marginBottom: [0, 0, 0, "28px"],
                  "&  a": {
                    color: get("palette.text.dark_gold"),
                  },
                }}
              >
                <Link
                  disabled={r.disabled}
                  id={r.id}
                  href={r.href}
                  handleHoverStart={changeActivePicture}
                  handleHoverEnds={unsetActivePicture}
                >
                  {r.title}
                </Link>
                {r.image && (
                  <MotionBox
                    sx={{
                      position: "absolute",
                      display: ["none", "block", "block", "block"],
                      right: 0,
                      top: "50%",
                      height: "100%",
                      width: ["200px", "200px", "223px", "380px"],
                      maxWidth: ["200px", "200px", "223px", "max(223px, 30%)"],
                      transform: "translateY(-50%)",
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: activePicture === r.id ? 1 : 0 }}
                  >
                    <Box
                      as={GatsbyImage}
                      alt=""
                      objectFit="contain"
                      image={r.image.childImageSharp.gatsbyImageData as any}
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </MotionBox>
                )}
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "5%",
            width: "100%",
            display: "flex",
            justifyContent: ["center", "center", "flex-end", "flex-end"],
            flexDirection: "row",
            gap: "2rem",
            color: get("palette.text.dark_gold"),
            margin: "0 auto",
            paddingRight: ["0", "0", "5%", "5%"],
          }}
        >
          <SmallTextWrapper
            sx={{
              cursor: "pointer",
            }}
            as={"a"}
            href={langVersLinks.ru}
          >
            RU
          </SmallTextWrapper>
          <SmallTextWrapper
            sx={{
              cursor: "pointer",
            }}
            as={"a"}
            href={langVersLinks.en}
          >
            EN
          </SmallTextWrapper>
        </Box>
      </Box>
    </Fade>
  );
};

export default React.memo(NavigationModal);
