import api from "..";
import { Order } from "../../models/Order";
import { SubscriptionDto } from "../../models/Subscription";

export const createNewOrder = async (order: Order) => {
  return await api.post(`/order/`, order);
};

export const subscribeToNewsletter = async (
  subscriptionDto: SubscriptionDto
) => {
  return await api.post(`/subscribe/`, subscriptionDto);
};
