import React from "react";

function useHasMounted() {
  const [isMounted, setIsMounted] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted;
}

export default useHasMounted;
