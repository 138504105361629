import React from "react";
import CheckboxInput from "./CheckboxInput";
import useLocale from "../hooks/useLocale";
import { useFormContext } from "react-hook-form";
import { SxProp } from "../constants/sx";
import { isMobile } from "react-device-detect";

export default function PersonalDataConsent(props: SxProp) {
  const locale = useLocale();
  const form = useFormContext();

  const handleConsentClicked = React.useCallback(() => {
    form.setValue(
      "personalDataConsent",
      !form.getValues("personalDataConsent"),
      {
        shouldValidate: true,
      }
    );
  }, [form]);

  return (
    <CheckboxInput
      register={form.register("personalDataConsent", {
        required: true,
      })}
      error={!!form.formState.errors.personalDataConsent}
      onClick={handleConsentClicked}
      sx={{ fontSize: "12px", lineHeight: 1, marginTop: "30px" }}
      {...props}
    >
      <div>
        {locale === "ru" ? "Я ознакомился c" : "I have read the"}{" "}
        <a
          href="/policy/"
          target="_blank"
          rel="noreferrer noopener"
          style={{
            color: "inherit",
            display: "inline",
            textDecoration: "underline",
          }}
        >
          {" "}
          {locale === "ru" ? "Политикой конфиденциальности" : "Privacy Policy"}
        </a>{" "}
        {locale === "ru" && !isMobile ? <br /> : null}
        {locale === "ru"
          ? "и даю согласие на обработку персональных данных"
          : "and I accept that my personal information will be processed for this purpose in compliance with Privacy Notice"}
      </div>
    </CheckboxInput>
  );
}
