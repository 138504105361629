import { SystemStyleObject } from "@styled-system/css";
import { Variants } from "framer-motion";
import React from "react";
import MotionBox from "../Box/MotionBox";

type Props = {
  children: React.ReactNode;
  onAnimationComplete?: () => void;
  sx?: SystemStyleObject;
};

export const defaultFadeVariants: Variants = {
  open: {
    opacity: 1,
    transition: {
      duration: 0.75,
      ease: "easeInOut",
    },
  },
  closed: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
};

const Fade = ({ onAnimationComplete, children, sx }: Props) => {
  const handleAnimationComplete = React.useCallback(() => {
    onAnimationComplete && onAnimationComplete();
  }, [onAnimationComplete]);

  return (
    <MotionBox
      initial="closed"
      animate="open"
      exit="closed"
      onAnimationComplete={handleAnimationComplete}
      variants={{ ...defaultFadeVariants }}
      sx={{ ...sx }}
    >
      {children}
    </MotionBox>
  );
};

export default React.memo(Fade);
