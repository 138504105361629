import css from "@styled-system/css";
import styled from "styled-components";
import { SxProp } from "../../constants/sx";

const FormRequestSpinner = styled.div<
  SxProp & {
    color: string | ((props: any) => any);
    bg: string | ((props: any) => any);
  }
>`
  font-size: 5px;
  text-indent: -99999em;
  border-radius: 50%;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: ${(p) => p.color && `inset 0 0 0 2px ${p.color}`};
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  &:before {
    position: absolute;
    content: "";
    width: 5.2em;
    height: 10.2em;
    background: ${(p) => p.bg && p.bg};
    border-radius: 10.2em 0 0 10.2em;
    top: -0.1em;
    left: -0.1em;
    -webkit-transform-origin: 5.1em 5.1em;
    transform-origin: 5.1em 5.1em;
    -webkit-animation: load2 2s infinite ease 1.5s;
    animation: load2 2s infinite ease 1.5s;
  }
  &:after {
    position: absolute;
    content: "";
    width: 5.2em;
    height: 10.2em;
    background: ${(p) => p.bg && p.bg};
    border-radius: 0 10.2em 10.2em 0;
    top: -0.1em;
    left: 4.9em;
    -webkit-transform-origin: 0.1em 5.1em;
    transform-origin: 0.1em 5.1em;
    -webkit-animation: load2 2s infinite ease;
    animation: load2 2s infinite ease;
  }

  ${(p) => p.sx && css({ ...p.sx })}

  @keyframes load2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export default FormRequestSpinner;
