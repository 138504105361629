import { SystemStyleObject } from "@styled-system/css";
import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import styled from "styled-components";
import { SxProp } from "../constants/sx";
import { get } from "../constants/theme";
import Box from "./Box/Box";

export const StyledCheckboxInput = styled.input.attrs({
  type: "checkbox",
})<SxProp>`
  appearance: none;
  outline: none;
  position: absolute;
  width: 1px;
  height: 1px;
  &:disabled + label:before {
    background: #ececec;
    color: #ececec;
  }
  & + label {
    font-family: custom_42266;
    cursor: pointer;
    display: flex;
    position: relative;
  }
  &[type="checkbox"] + label:before {
    flex: none;
    content: " ";
    color: ${get("palette.text.dark_gold")};
    display: inline-block;
    font-size: 20px;
    margin-right: 15px;
    position: relative;
    text-align: center;
    text-indent: 0px;
    width: 20px;
    height: 20px;
    background: transparent;
    border: 1px solid ${get("palette.text.dark_gold")};
    border-image: initial;
    vertical-align: middle;
  }

  &:not(disabled) + label:hover {
    opacity: 0.85;
  }

  & + label:after {
    content: "";
    position: absolute;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    left: 3px;
    top: 3px;
    width: 14px;
    height: 8px;
    border-radius: 1px;
    border-left: 2px solid ${get("palette.text.dark_gold")};
    border-bottom: 2px solid ${get("palette.text.dark_gold")};
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  &:checked + label:after {
    opacity: 1;
  }

  &:not(checked) + label:after {
    opacity: 0;
  }
`;

type Props = {
  register: UseFormRegisterReturn;
  onClick: () => void;
  children: React.ReactNode;
  sx?: SystemStyleObject;
  error: boolean;
};
const CheckboxInput = ({
  register,
  children,
  onClick,
  sx,
  error = false,
}: Props) => {
  return (
    <Box
      sx={{
        position: "relative",
        textAlign: "left",
        ...sx,
        ...(error && { color: get("palette.error") }),
      }}
    >
      <StyledCheckboxInput type="checkbox" {...register} />
      <label onClick={onClick} htmlFor="checkbox">
        {children}
      </label>
    </Box>
  );
};

export default React.memo(CheckboxInput);
