import axios, { AxiosRequestConfig } from "axios";

const config: AxiosRequestConfig = {
  baseURL: "/api",
  headers: {
    "Content-type": "application/json",
  },
};

const api = axios.create(config);

export default api;
