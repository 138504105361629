import { AnimatePresence, useViewportScroll } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from "../../Box/Box";
import MotionBox from "../../Box/MotionBox";
import { get } from "../../../constants/theme";
import LogoRu from "../../../../assets/logo.inline.svg";
import LogoEn from "../../../../assets/logo_en.inline.svg";
import NavigationModal from "../../NavigationModal/NavigationModal";
import { Link, graphql, useStaticQuery } from "gatsby";
import { HeaderQuery } from "../../../types/components/header";
import { SxProp } from "../../../constants/sx";
import useLocale from "../../../hooks/useLocale";

enum ScrollDirection {
  Up,
  Down,
}

const Header = ({
  menuInitialColor,
  ...props
}: {
  menuInitialColor?: string;
} & SxProp) => {
  const ref = useRef<HTMLElement | null>(null);
  const [top, setTop] = useState(0);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(
    ScrollDirection.Down
  );
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const { scrollY } = useViewportScroll();
  const locale = useLocale();

  const height = ref?.current?.clientHeight || 0;
  const isOpaque = top;
  const isFixed = top > lastScrollPosition;

  const handleScrollChange = useCallback(
    (scrollYValue: number) => {
      scrollYValue = Math.max(0, scrollYValue);
      const newScrollDirection =
        scrollYValue > lastScrollPosition
          ? ScrollDirection.Down
          : ScrollDirection.Up;

      if (scrollYValue === 0) {
        setTop(0);
      } else {
        if (newScrollDirection !== scrollDirection) {
          if (newScrollDirection === ScrollDirection.Up) {
            setTop(Math.max(top, scrollYValue - height));
          } else {
            setTop(Math.min(top, scrollYValue));
          }
        }
      }

      setScrollDirection(newScrollDirection);
      setLastScrollPosition(scrollYValue);
    },
    [height, lastScrollPosition, scrollDirection, top]
  );

  useEffect(() => {
    const unsubscribe = scrollY.onChange(handleScrollChange);
    return () => unsubscribe();
  }, [scrollY, handleScrollChange]);

  function handleMenuClick() {
    setIsMenuOpened(!isMenuOpened);
  }

  const { translationsJson } = useStaticQuery<HeaderQuery>(graphql`
    query HeaderQuery {
      translationsJson {
        components {
          layout {
            header {
              contacts
            }
          }
        }
      }
    }
  `);

  return (
    <Box
      as="header"
      {...props}
      ref={ref}
      sx={{
        height: ["90px", "100px"],
        padding: ["16px 24px", "20px 5% 22px 5%"],
        position: isFixed ? "fixed" : "absolute",
        top: isFixed ? 0 : top,
        left: 0,
        width: "100%",
        zIndex: 200,
        color: menuInitialColor || "white",
        transition: ["background", "color", "box-shadow"]
          .map((prop) => `${prop} 0.5s ease-in-out`)
          .join(","),
        ...(isOpaque
          ? {
              background: "white",
              color: get("palette.text.dark_gold"),
              boxShadow: "0px 4px 10px 0px #0000000D",
            }
          : null),
      }}
    >
      <AnimatePresence>{isMenuOpened && <NavigationModal />}</AnimatePresence>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr auto 1fr",
          alignItems: "center",
          fontSize: [null, "20px"],
        }}
      >
        <Box />
        <Box as={Link} to="/" sx={{ color: "inherit" }}>
          {locale === "ru" ? (
            <LogoRu style={{ width: "auto", height: "58px" }} />
          ) : (
            <LogoEn style={{ width: "auto", height: "58px" }} />
          )}
        </Box>
        <Box
          as={Link}
          to="/contacts"
          sx={{
            justifySelf: "end",
            color: "inherit",
            textTransform: "uppercase",
            fontFamily: "custom_42266",
          }}
        >
          {translationsJson.components.layout.header.contacts}
        </Box>
      </Box>

      <MotionBox
        sx={{
          cursor: "pointer",
          position: "absolute",
          left: ["16px", "5%"],
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 101,
        }}
        onClick={handleMenuClick}
      >
        <Box
          sx={{
            height: [40, 40, 46, 46],
            width: [40, 40, 46, 46],
            position: "relative",
            "& > :nth-child(1n)": {
              position: "absolute",
              height: ["1.5px", "3px", "3px", "3px"],
              width: "100%",
              transition: ["background"]
                .map((prop) => `${prop} 0.5s ease-in-out`)
                .join(","),
              background:
                isOpaque || isMenuOpened
                  ? get("palette.text.dark_gold")
                  : menuInitialColor || "white",
            },
          }}
        >
          <MotionBox
            animate={{
              rotate: isMenuOpened ? "45deg" : "180deg",
              top: isMenuOpened ? "50%" : "35%",
              translateY: "-50%",
              transition: {
                ease: "easeInOut",
                duration: 0.2,
              },
            }}
          />
          <MotionBox
            animate={{
              rotate: isMenuOpened ? "-45deg" : "-180deg",
              top: isMenuOpened ? "50%" : "65%",
              translateY: "-50%",
              transition: {
                ease: "easeInOut",
                duration: 0.2,
              },
            }}
          />
        </Box>
      </MotionBox>
    </Box>
  );
};

export default React.memo(Header);
