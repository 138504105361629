import { SystemStyleObject } from "@styled-system/css";
import React, { HTMLInputTypeAttribute } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import styled from "styled-components";
import { SxProp } from "../constants/sx";
import { get } from "../constants/theme";
import Box from "./Box/Box";

export const StyledInput = styled("input")<SxProp & { autofill?: { bg: any } }>`
  font-family: custom_42266;
  font-style: normal;
  font-weight: 400;
  font-size: inherit;
  width: 100%;
  color: ${get("palette.text.dark_gold")};
  font-feature-settings: "liga" 0, "rlig" 0, "dlig" 0, "hlig" 0, "smcp" 0;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  line-height: 42px;
  background: transparent;
  outline: 0;
  border-radius: 0;
  border: 0;
  box-shadow: none;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    font-family: custom_42266;
    font-style: normal;
    font-weight: 400;
    box-shadow: 0 0 0 30px ${(p) => (p.autofill ? p.autofill.bg : "inherit")}
      inset !important;
    -webkit-box-shadow: 0 0 0 30px
      ${(p) => (p.autofill ? p.autofill.bg : "inherit")} solid !important;
    background-color: ${(p) =>
      p.autofill ? p.autofill.bg : "inherit"} !important;
    background-clip: content-box !important;
    caret-color: ${get("palette.text.dark_gold")};
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: ${get("palette.text.dark_gold")} !important;
  }

  &::placeholder {
    text-transform: uppercase;
  }
`;

type Props = {
  placeholder?: string;
  type: HTMLInputTypeAttribute;
  register: UseFormRegisterReturn;
  sx?: SystemStyleObject;
  autoComplete?: "off" | "on";
  error?: boolean;
  autofil: {
    bg: any;
  };
};

const Input = ({
  placeholder = "",
  autoComplete = "on",
  type,
  register,
  error = false,
  sx,
  autofil,
}: Props) => {
  return (
    <Box
      sx={{
        marginBottom: "8px",
        fontSize: "20px",
      }}
    >
      <StyledInput
        autofill={autofil}
        sx={{
          ...sx,
        }}
        type={type}
        multiple={false}
        placeholder={placeholder}
        spellCheck={false}
        autoComplete={autoComplete}
        {...register}
      />
      <Box
        sx={{
          width: "100%",
          height: 2,
          background: !error
            ? get("palette.text.dark_gold")
            : get("palette.error"),
        }}
      />
    </Box>
  );
};

export default React.memo(Input);
