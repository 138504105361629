import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import { getSrc, ImageDataLike } from "gatsby-plugin-image";

interface ISeoQuery {
  site: {
    siteMetadata: {
      title: string;
      author: string;
      description: string;
      keywords: string;
      lang: string;
      siteName: string;
      siteUrl: string;
      phone: string;
      social: {
        telegram: string;
      };
    };
  };
}

interface Props {
  description: string;
  lang?: string;
  meta?: Array<any>;
  keywords?: string;
  pathname: string;
  title: string;
  metaImage?: ImageDataLike | undefined;
}

const Seo = (props: Props) => {
  const { title, siteUrl, siteName, description, keywords, lang } =
    useStaticQuery<ISeoQuery>(
      graphql`
        query DefaultSEOQuery {
          site {
            siteMetadata {
              title
              keywords
              siteUrl
              siteName
              lang
              description
              author
              social {
                telegram
              }
            }
          }
        }
      `
    ).site.siteMetadata;

  const meta = props.meta ? props.meta : [];
  const metaTitle = props.title || title;
  const metaKey = props.keywords || keywords;
  const metaDescription = props.description || description;
  const metaImage = props.metaImage ? getSrc(props.metaImage) : null;
  // const metaImage = image && image.src ? `${data.site.siteMetadata.siteUrl}${image.src}` : null
  const metaUrl = `${siteUrl}${props.pathname}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKey,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:site_name`,
          content: siteName,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  property: `og:image`,
                  content: `${siteUrl}${metaImage}`,
                },
              ]
            : []
        )
        .concat(meta)}
    />
  );
};

export default Seo;
