import styled from "styled-components";
import { motion } from "framer-motion";
import sx from "../../constants/sx";
import {
  color,
  layout,
  space,
  typography,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow,
} from "styled-system";
import Box from "./Box";

const MotionBox = styled(motion(Box))(
  space,
  color,
  typography,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow,
  sx
);

export default MotionBox;
